import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Banner from '../components/Banner'
import Content from '../components/Privacy/Content'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Privacy({ pageContext }) {
  const { t } = useTranslation()

  return (
    <Layout
      className="with-banner terms"
      locale={pageContext.language}
      template="privacy"
    >
      <Header black>
        <Banner title={t('page_privacy_title')} />
      </Header>
      <main>
        <Content />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
