import React from 'react'

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const Content = () => {
  const { t } = useTranslation()

  return (
    <>
      <section className="intro_terms">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="intro__title section-title">
                {t('page_privacy_subtitle')}
              </h4>
            </div>

            <div className="col-12">
              <p className="intro__text">
                <Trans>page_privacy_paragraph</Trans>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Content
